import React, { ReactNode } from "react";
import { Box, Collapse } from "@mantine/core";

type FolderChildrenProps = {
    opened: boolean;
    children: ReactNode;
    ml: string;
};

export const FolderChildren = ({
    opened,
    children,
    ml,
}: FolderChildrenProps) => (
    <Box ml={ml}>
        <Collapse in={!!opened}>{children}</Collapse>
    </Box>
);
