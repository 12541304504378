import { DraggableNodeItem } from "./draggable-node-item";
import { DraggableNodeItemProps } from "./draggable.interface";

export function DraggableWrapper<T>({
    children,
    ...props
}: DraggableNodeItemProps<T>) {
    if (!props.canDragAndDrop) {
        return <>{children}</>;
    }

    return <DraggableNodeItem {...props}>{children}</DraggableNodeItem>;
}
