import React from "react";

import { InputProps } from "./input.interface";
import { StyledInput, StyledTextarea } from "./input.styled";
import { MaskedStyledInput, MaskedStyledTextarea } from "./input.masked";

export const Input = React.forwardRef<HTMLInputElement, InputProps>(
    (
        {
            error,
            value = "",
            onChange,
            type = "text",
            mask,
            isTextarea,
            onFocus,
            ...props
        },
        ref,
    ) => {
        const handleOnChange = (e: React.ChangeEvent<HTMLInputElement>) => {
            if (onChange) {
                if (type === "number") {
                    onChange(
                        e.target.value.length
                            ? Number(e.target.value)
                            : undefined,
                    );
                } else {
                    onChange(e.target.value);
                }
            }
        };

        const handleOnFocus = (e: React.FocusEvent<HTMLInputElement>) => {
            e.target.addEventListener("wheel", function (e) {
                e.preventDefault();
            });
            onFocus?.();
        };

        if (mask) {
            return isTextarea ? (
                <MaskedStyledTextarea
                    $error={error}
                    mask={mask}
                    inputRef={ref}
                    {...(onChange ? { value, onChange: handleOnChange } : {})}
                    {...props}
                />
            ) : (
                <MaskedStyledInput
                    $error={error}
                    mask={mask}
                    inputRef={ref}
                    type={type}
                    onFocus={handleOnFocus}
                    {...(onChange ? { value, onChange: handleOnChange } : {})}
                    {...props}
                />
            );
        }

        const Component = isTextarea ? StyledTextarea : StyledInput;

        return (
            <Component<any>
                autoComplete="auto"
                ref={ref}
                type={type}
                $error={error}
                onFocus={handleOnFocus}
                {...(onChange ? { value, onChange: handleOnChange } : {})}
                {...props}
            />
        );
    },
);

Input.displayName = "Input";
