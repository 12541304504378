import React from "react";

import { NodeContainer, NodeControlLeftBlock } from "../node-render.styled";
import { NodeItemIcon } from "../node-item-icon/node-item-icon";
import { CollapseTrigger } from "../collapse-trigger/collapse-trigger";
import { NodeTitle } from "../node-title/node-title";
import { FolderParentRowProps } from "../../node-render.interface";
import { DragAndDropButton } from "../drag-and-drop-button/drag-and-drop-button";

export const FolderParentRow = ({
    isRoot,
    nodeProps,
    opened,
    toggleCollapse,
    isDraggable,
    hasChildren,
}: FolderParentRowProps) => {
    return (
        <>
            <NodeContainer>
                <NodeControlLeftBlock>
                    {hasChildren && (
                        <CollapseTrigger
                            opened={opened}
                            onClick={toggleCollapse}
                        />
                    )}

                    {isDraggable && <DragAndDropButton />}
                    <NodeItemIcon isRoot={!!isRoot} />
                    <NodeTitle title={nodeProps.title} />
                </NodeControlLeftBlock>
            </NodeContainer>
        </>
    );
};
