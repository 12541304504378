import React from "react";
import { IMaskMixin } from "react-imask";

import { StyledInput, StyledTextarea } from "./input.styled";

export const MaskedStyledInput = IMaskMixin(
    ({ inputRef, onChange, value, ...props }) => (
        <StyledInput<any>
            ref={inputRef}
            {...(onChange ? { value, onChange } : {})}
            {...props}
        />
    ),
);

export const MaskedStyledTextarea = IMaskMixin(
    ({ inputRef, onChange, value, ...props }) => (
        <StyledTextarea<any>
            ref={inputRef}
            {...(onChange ? { value, onChange } : {})}
            {...props}
        />
    ),
);
