import { NodeTitle } from "../node-title/node-title";
import { NodeContainer, NodeControlContent } from "../node-render.styled";
import { DragAndDropButton } from "../drag-and-drop-button/drag-and-drop-button";
import { NodeItemProps } from "../../node-render.interface";

export const NodeItem = ({
    nodeProps,
    isDraggable,
    isSelected,
}: NodeItemProps) => {
    return (
        <NodeContainer isSelected={isSelected}>
            <NodeControlContent>
                {isDraggable && <DragAndDropButton />}
                <NodeTitle title={nodeProps.title} />
            </NodeControlContent>
        </NodeContainer>
    );
};
