import React, { useMemo } from "react";
import { DndProvider, DndProviderProps } from "react-dnd";
import { TouchBackend } from "react-dnd-touch-backend";

import { CustomDragLayer } from "./node/draggable/custom-drag-layer";
import { TreeStateProvider } from "./provider/TreeStateProvider";
import { TreeProps } from "./tree.interface";
import { Node } from "./node/node";
import { getTreeDepth } from "./utils/get-tree-depth";
import { TreeContainer } from "./tree.styled";
import { HorizontalScrollableBlockWithShadow } from "components/atoms/horizontal-scrollable-block-with-shadow/horizontal-scrollable-block-with-shadow.component";

export const Tree: <T>(p: TreeProps<T>) => React.ReactElement = ({
    canDragAndDrop = true,
    rootId,
    nodes,
    ...props
}) => {
    let dndProviderProps: DndProviderProps<any, any>;

    if (process.env.DND_TEST_MODE) {
        // eslint-disable-next-line
        const backend = eval("require('react-dnd-html5-backend').HTML5Backend");
        dndProviderProps = { backend };
    } else {
        dndProviderProps = {
            backend: TouchBackend,
            options: { enableMouseEvents: true },
        };
    }

    const treeDepth = useMemo(() => getTreeDepth(nodes), [nodes]);

    const isRootNodeSkipped = useMemo(
        () => nodes[rootId].skipRender,
        [nodes, rootId],
    );

    return (
        <HorizontalScrollableBlockWithShadow
            customShadowHeight={
                isRootNodeSkipped ? "calc(100% - 10px)" : "100%"
            }
        >
            <TreeContainer depth={treeDepth}>
                <DndProvider {...dndProviderProps}>
                    <TreeStateProvider nodes={nodes}>
                        <Node
                            {...props}
                            id={rootId}
                            canDragAndDrop={canDragAndDrop}
                        />
                        <CustomDragLayer nodeRender={props.nodeRender} />
                    </TreeStateProvider>
                </DndProvider>
            </TreeContainer>
        </HorizontalScrollableBlockWithShadow>
    );
};
