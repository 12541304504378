import React from "react";

import { InputLabelProps } from "./input-label.interface";
import { StyledLabel } from "./input-label.styled";

export const InputLabel = ({ id, label, required }: InputLabelProps) => {
    return (
        <StyledLabel htmlFor={id}>
            {label} {required && <span>*</span>}
        </StyledLabel>
    );
};
