import React, { useMemo } from "react";

import { Icon } from "components/molecules/icon/icon.component";
import { StyledBlock } from "./node-item-icon.styled";
import { Icons } from "components/molecules/icon/icon.interface";

type NodeItemIconProps = {
    isRoot?: boolean;
    isReport?: boolean;
};

export const NodeItemIcon = ({ isRoot, isReport }: NodeItemIconProps) => {
    const iconName = useMemo(() => {
        if (isReport) {
            return Icons.reportIconSvg;
        }
        if (isRoot) {
            return Icons.eagleSvg;
        }
        return Icons.folderSvg;
    }, [isRoot, isReport]);

    return (
        <StyledBlock width={isRoot ? "42px" : "24px"}>
            <Icon name={iconName} />
        </StyledBlock>
    );
};
