import styled from "styled-components";

import { colors } from "lib/palette";
import { breakpoints } from "lib/breakpoints";
import { zIndexes } from "lib/z-indexes";

export const NodeContainer = styled.div<{ isSelected?: boolean }>`
    height: 46px;
    border: 1px solid
        ${({ isSelected }) => (isSelected ? colors.purple_3 : colors.blue_3)};
    border-radius: 5px;
    margin-bottom: 3px;
    display: flex;
    background-color: ${colors.white};
    width: 100%;

    &:hover {
        border: 1px solid ${colors.purple_3};
    }
`;

export const NodeControlContent = styled.div`
    display: flex;
    align-items: center;
    flex: 1;
    justify-content: space-between;
    gap: 15px;
    padding: 0 15px;
    overflow: hidden;

    @media (max-width: ${breakpoints.sm}px) {
        padding-right: 10px;
    }
`;

export const NodeControlLeftBlock = styled.div`
    display: flex;
    align-items: center;
    flex: 1;
    gap: 12px;
    overflow: hidden;
`;

export const NodeControlRightBlock = styled.div`
    width: max-content;
    display: flex;
    align-items: center;
    gap: 12px;
`;

export const DragZone = styled.div`
    width: 100%;
    height: 100%;
    cursor: pointer;
`;

export const DropZone = styled.div`
    width: 100%;
    height: 100%;
`;

export const StyledDragRow = styled.div`
    height: 46px;
    border-radius: 5px;
    width: 100%;
    background-color: ${colors.blue_4};
    margin: 3px 0;
    position: absolute;
    top: -3px;
    z-index: ${zIndexes.coverDndRow};
`;

export const StyledAvailableZone = styled.div`
    display: flex;
    border-radius: 5px;
    height: 4px;
    margin-bottom: 4px;
    background-color: ${colors.purple_3};
`;
