import { Icon } from "components/molecules/icon/icon.component";
import { Icons } from "components/molecules/icon/icon.interface";
import { StyledButton } from "./drag-and-drop-button.styled";

export const DragAndDropButton = () => {
    return (
        <StyledButton>
            <Icon name={Icons.dragAndDropSvg} />
        </StyledButton>
    );
};
