import styled from "styled-components";

import { colors } from "lib/palette";

export const StyledButton = styled.button`
    min-width: 6px;
    background: ${colors.transparent};
    padding: 0;
    display: flex;
    border: none;
`;
