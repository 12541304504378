import styled from "styled-components";

import { colors } from "lib/palette";

export const StyledLabel = styled.label`
    font-weight: 600;
    font-size: 13px;
    line-height: 20px;
    color: ${colors.black_1};

    span {
        color: ${colors.red_1};
    }
`;
