import { PropsWithChildren, useEffect, useRef, useState } from "react";

import {
    StyledScrollableBlock,
    StyledShadowBlock,
} from "./horizontal-scrollable-block-with-shadow.styled";

export const HorizontalScrollableBlockWithShadow = ({
    children,
    customShadowHeight,
}: PropsWithChildren<{ customShadowHeight?: string }>) => {
    const ref = useRef<HTMLDivElement>(null);
    const [classNames, setClassNames] = useState({
        leftShadow: false,
        rightShadow: false,
    });

    useEffect(() => {
        const element = ref.current;

        const handleSetShadowState = () => {
            setClassNames({
                leftShadow: !!element && element.scrollLeft > 0,
                rightShadow:
                    !!element &&
                    element.scrollWidth -
                        element?.offsetWidth -
                        element.scrollLeft >
                        0,
            });
        };

        handleSetShadowState();

        element?.addEventListener("scroll", handleSetShadowState);
        window.addEventListener("resize", handleSetShadowState);

        return () => {
            window.removeEventListener("resize", handleSetShadowState);
            element?.removeEventListener("scroll", handleSetShadowState);
        };
    }, [ref.current?.scrollWidth, ref.current?.offsetWidth]);

    return (
        <StyledShadowBlock
            customShadowHeight={customShadowHeight}
            className={`${classNames.leftShadow ? "withLeftShadow" : ""} ${classNames.rightShadow ? "withRightShadow" : ""}`}
        >
            <StyledScrollableBlock ref={ref}>{children}</StyledScrollableBlock>
        </StyledShadowBlock>
    );
};
