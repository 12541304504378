import { FormFieldProps, testIds } from "./form-field.interface";
import { Tooltip } from "../tooltip/tooltip.component";
import { InputLabel } from "../input-label/input-label.component";
import {
    StyledErrorMessageContainer,
    StyledFieldContainer,
    StyledHeader,
} from "./form-field.styled";

export const FormField = ({
    children,
    label,
    required,
    errorMessage,
    footer,
    id,
    className,
    tooltip,
    testId,
}: FormFieldProps) => {
    const showHeader = !!tooltip || !!label;

    return (
        <StyledFieldContainer className={className}>
            {showHeader && (
                <StyledHeader>
                    {!!label && (
                        <InputLabel id={id} label={label} required={required} />
                    )}
                    {!!tooltip && (
                        <Tooltip
                            message={tooltip}
                            testId={
                                testId
                                    ? `${testId}-${testIds.tooltip}`
                                    : testIds.tooltip
                            }
                        />
                    )}
                </StyledHeader>
            )}
            {children}
            {errorMessage && (
                <StyledErrorMessageContainer>
                    {errorMessage}
                </StyledErrorMessageContainer>
            )}
            {footer}
        </StyledFieldContainer>
    );
};
