import React from "react";

import {
    UpDownArrow,
    ArrowViews,
} from "components/atoms/up-down-arrow/up-down-arrow";
import { CollapseTriggerContainer } from "./collapse-trigger.styled";

type CollapseTriggerProps = {
    opened: boolean;
    onClick: () => void;
    testId?: string;
};

export const CollapseTrigger = ({
    opened,
    onClick,
    testId,
}: CollapseTriggerProps) => {
    return (
        <CollapseTriggerContainer data-testid={testId} onClick={onClick}>
            <UpDownArrow view={opened ? ArrowViews.UP : ArrowViews.DOWN} />
        </CollapseTriggerContainer>
    );
};
