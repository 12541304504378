import { Box } from "@mantine/core";

import { Icon } from "components/molecules/icon/icon.component";
import { Icons } from "components/molecules/icon/icon.interface";

export const enum ArrowViews {
    UP = "up",
    DOWN = "down",
}

type UpDownArrowProps = {
    view: `${ArrowViews}`;
};

export const UpDownArrow = ({ view }: UpDownArrowProps) => {
    const deg = view === ArrowViews.UP ? 0 : 180;

    return (
        <Box
            sx={{
                width: "10px",
                height: "10px",
                display: "flex",
                transition: "0.2s",
                transform: `rotate(${deg}deg)`,
            }}
        >
            <Icon name={Icons.upArrowSvg} />
        </Box>
    );
};
