import styled from "styled-components";

import { breakpoints } from "lib/breakpoints";
import { colors } from "lib/palette";
import { zIndexes } from "lib/z-indexes";

export const StyledInput = styled.div<{ isFullWidth?: boolean }>`
    ${({ isFullWidth }) => isFullWidth && "width: 100%"};
    display: inline-block;
    position: relative;

    &::before {
        content: "";
        display: inline-block;
        position: absolute;
        z-index: ${zIndexes.searchInputIcon};
        top: 6px;
        left: 6px;
        bottom: 6px;
        width: 24px;
        height: 24px;
        background-image: url("data:image/svg+xml;charset=utf8,%3Csvg width='24' height='24' viewBox='0 0 24 24' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M15.5 15.5L19 19' stroke='%23D9D9D9' stroke-width='1.5' stroke-linecap='round' stroke-linejoin='round'/%3E%3Cpath d='M5 11C5 14.3137 7.68629 17 11 17C12.6597 17 14.1621 16.3261 15.2483 15.237C16.3308 14.1517 17 12.654 17 11C17 7.68629 14.3137 5 11 5C7.68629 5 5 7.68629 5 11Z' stroke='%23D9D9D9' stroke-width='1.5' stroke-linecap='round' stroke-linejoin='round'/%3E%3C/svg%3E");
        background-position: center;
        background-repeat: no-repeat;
    }

    & input {
        padding-right: 30px;
        padding-left: 35px;

        &::placeholder {
            color: ${colors.black_3};
        }
    }

    .mantine-Input-input[value=""] {
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
    }

    @media (max-width: ${`${breakpoints.sm}px`}) {
        width: 100%;

        .input-container {
            width: 100%;
        }

        .mantine-Input-wrapper {
            width: 100%;
        }
    }
`;

export const StyledClearButtonContainer = styled.div`
    width: max-content;
    height: max-content;
    position: absolute;
    margin: auto;
    inset: 0 6px 0 auto;
    z-index: ${zIndexes.searchInputClearIcon};
`;

export const StyledClearButton = styled.button`
    width: max-content;
    height: max-content;
    display: flex;
    padding: 0;
    background: transparent;
    border: none;
`;
