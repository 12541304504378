import React, { useCallback, useMemo } from "react";

import { NodeRender } from "../node-render/node-render";
import { FolderChildren } from "../node-render/components/folder/folder-children";
import { NodeProps } from "./node.interface";
import { useTreeContext } from "../provider/use-tree-context";
import { DraggableWrapper } from "./draggable/draggable-wrapper";
import { EmptyNode, StyledNode } from "./node.styled";

export function Node<T>(props: NodeProps<T>): React.ReactElement {
    const { nodes, openedNodes, toggleCollapseById, draggingNodeId } =
        useTreeContext<T>();

    const { id, nodeRender } = props;

    const { children, skipRender, ...item } = nodes[id];
    const Render = useMemo(() => nodeRender ?? NodeRender, [nodeRender]);
    const toggleCollapse = useCallback(
        () => toggleCollapseById(id),
        [id, toggleCollapseById],
    );

    const RenderMemoized = useMemo(() => {
        return skipRender ? (
            <EmptyNode />
        ) : (
            // eslint-disable-next-line @typescript-eslint/ban-ts-comment
            // @ts-ignore
            <Render
                {...item}
                opened={openedNodes[id]}
                toggleCollapse={toggleCollapse}
                hasChildren={!!children.length}
            />
        );
    }, [
        skipRender,
        Render,
        item,
        openedNodes,
        id,
        toggleCollapse,
        children.length,
    ]);

    return (
        <StyledNode id={id}>
            <DraggableWrapper {...props}>{RenderMemoized}</DraggableWrapper>
            <FolderChildren
                ml={skipRender ? "0px" : "25px"}
                opened={openedNodes[id] && id !== draggingNodeId}
                key={`${id}-children-${children.length}`}
            >
                {children.map((childId) => (
                    <Node {...props} key={childId} id={childId} />
                ))}
            </FolderChildren>
        </StyledNode>
    );
}
