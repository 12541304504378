import styled from "styled-components";

export const StyledNode = styled.div`
    position: relative;
    user-select: none;
`;

export const EmptyNode = styled.div`
    padding: 5px;
`;
