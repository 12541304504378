import React, { useCallback, useEffect, useMemo, useState } from "react";

import {
    TreeStateContextType,
    TreeStateProviderProps,
} from "./tree-context.interface";

export const TreeStateContext = React.createContext<TreeStateContextType<any>>({
    nodes: {},
    draggingNodeId: null,
    setDraggingNodeId: () => ({}),
    openedNodes: {},
    toggleCollapseById: () => ({}),
});

export function TreeStateProvider<T>({
    nodes,
    children,
}: TreeStateProviderProps<T>) {
    const [value, setValue] = useState(nodes);
    const [openedNodes, setOpenedNodes] = useState<Record<string, boolean>>({});
    const [draggingNodeId, setDraggingNodeId] = useState<string | null>(null);

    // TODO: `nodes` is often used in the tree structure, need to exclude the transfer of selectedItemId from treeData.
    const folderNodes = useMemo(
        () =>
            Object.keys(value).reduce(
                (acc, node) => {
                    if (value[node].isFolder) {
                        acc[node] = true;
                    }
                    return acc;
                },
                {} as Record<string, boolean>,
            ),
        [value],
    );

    useEffect(() => {
        setOpenedNodes((prevOpenedNodes) => {
            const handleFolderNodes = { ...prevOpenedNodes };
            Object.keys(folderNodes).forEach((id) => {
                if (!(id in handleFolderNodes)) {
                    handleFolderNodes[id] = true;
                }
            });
            return handleFolderNodes;
        });
    }, [folderNodes]);

    useEffect(() => setValue(nodes), [nodes]);

    const toggleCollapseById = useCallback(
        (id: string) => {
            setOpenedNodes({ ...openedNodes, [id]: !openedNodes[id] });
        },
        [openedNodes],
    );

    return (
        <TreeStateContext.Provider
            value={{
                openedNodes,
                nodes: value,
                toggleCollapseById,
                draggingNodeId,
                setDraggingNodeId,
            }}
        >
            {children}
        </TreeStateContext.Provider>
    );
}
