import { useMemo } from "react";
import { useDrag, useDrop } from "react-dnd";

import { Item } from "./tree.interface";

type UseDragAndDropParams<T> = {
    node: Item<T>;
    onMoveNode?: (dragNode: Item<T>, dropNode: Item<T>) => void;
    canDropNode?: (dragNode: Item<T>, dropNode: Item<T>) => boolean;
};

const DRAG_TYPE = "NODE";

export const useDragAndDrop = <T>({
    node,
    onMoveNode,
    canDropNode,
}: UseDragAndDropParams<T>) => {
    const [{ isOver, canDrop: isDroppable }, drop] = useDrop({
        accept: DRAG_TYPE,
        drop: (dragItem: Item<T>) => {
            if (!isOver || !isDroppable) {
                return;
            }

            onMoveNode?.(dragItem, node);
        },
        collect: (monitor) => ({
            isOver: monitor.isOver(),
            canDrop: monitor.canDrop(),
        }),
        canDrop: (dragItem) => {
            return !!canDropNode?.(dragItem, node);
        },
    });

    const [{ isDragging }, drag, preview] = useDrag({
        item: node,
        type: DRAG_TYPE,
        collect: (monitor) => ({
            isDragging: monitor.isDragging(),
        }),
        canDrag: node?.isDraggable,
    });

    const canDrop = useMemo(() => isOver && isDroppable, [isDroppable, isOver]);

    return {
        drop,
        drag,
        preview,
        isDragging,
        canDrop,
    };
};
