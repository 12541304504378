import React from "react";

import { Text } from "components/atoms/text/text.component";
import { colors } from "lib/palette";
import { StyledBlock } from "./node-title.styled";

type NodeTitleProps = {
    title: string;
};

export const NodeTitle = ({ title }: NodeTitleProps) => {
    return (
        <StyledBlock>
            <Text color={colors.black_1}>{title}</Text>
        </StyledBlock>
    );
};
