import { ReactNode, useEffect, useRef, useState } from "react";

import {
    DragZone,
    DropZone,
    StyledAvailableZone,
    StyledDragRow,
} from "../../node-render/components/node-render.styled";
import { useDragAndDrop } from "components/molecules/tree/use-drag-and-drop";
import { useTreeContext } from "components/molecules/tree/provider/use-tree-context";
import {
    DraggableNodeItemProps,
    SMOOTH_BACK_ANIMATION_TIME,
} from "./draggable.interface";
import { getEmptyImage } from "lib/utils/get-empty-image";

export function DraggableNodeItem<T>({
    id,
    onMoveNode,
    canDropNode,
    children,
}: DraggableNodeItemProps<T>): React.ReactElement {
    const [coverRow, setCoverRow] = useState<ReactNode | null>(null);

    const dropRef = useRef<HTMLInputElement>(null);
    const dragRef = useRef<HTMLInputElement>(null);

    const canDropRef = useRef(false);

    const { nodes, setDraggingNodeId, openedNodes, toggleCollapseById } =
        useTreeContext<T>();

    const { drop, drag, preview, isDragging, canDrop } = useDragAndDrop({
        node: nodes[id],
        onMoveNode,
        canDropNode,
    });

    const isDraggingFirstState = useRef(isDragging);
    const isDraggingUpdated = useRef(false);

    useEffect(() => {
        setDraggingNodeId(isDragging ? id : null);
    }, [id, isDragging, setDraggingNodeId]);

    useEffect(() => {
        preview(getEmptyImage());
    }, [preview]);

    useEffect(() => {
        if (canDrop && !openedNodes[id]) {
            setTimeout(() => {
                if (canDropRef.current) {
                    toggleCollapseById(id);
                }
            }, 500);
        }
    }, [canDrop, id, openedNodes, toggleCollapseById]);

    useEffect(() => {
        canDropRef.current = canDrop;
    }, [canDrop]);

    useEffect(() => {
        if (!isDraggingUpdated.current) {
            if (isDraggingFirstState.current === isDragging) {
                return;
            } else {
                isDraggingUpdated.current = true;
            }
        }

        if (isDragging) {
            setCoverRow(<StyledDragRow />);
        } else {
            setTimeout(() => {
                setCoverRow(null);
            }, SMOOTH_BACK_ANIMATION_TIME);
        }
    }, [isDragging]);

    drop(dropRef);
    drag(dragRef);

    return (
        <>
            {coverRow}
            <DragZone ref={dragRef}>
                <DropZone ref={dropRef}>{children}</DropZone>
            </DragZone>
            {canDrop && <StyledAvailableZone />}
        </>
    );
}
