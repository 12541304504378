import styled from "styled-components";

import { colors } from "lib/palette";

export const CollapseTriggerContainer = styled.div`
    display: flex;
    border-right: 1px solid ${colors.blue_3};
    cursor: pointer;
    align-items: center;
    justify-content: center;
    width: 24px;
    min-height: 46px;
    min-width: 24px;
`;
