import styled from "styled-components";

const nodeMinWidth = 280;
const childNodeLeftMargin = 25;

export const TreeContainer = styled.div<{ depth: number }>`
    width: 100%;
    min-width: ${({ depth }) =>
        (depth - 1) * childNodeLeftMargin + nodeMinWidth}px;
`;
