import styled from "styled-components";

export const StyledBlock = styled.div`
    width: 100%;
    overflow: hidden;

    p {
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
    }
`;
