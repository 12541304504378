import { ReactNode } from "react";

export type FormFieldProps = {
    children: ReactNode;
    errorMessage?: string | ReactNode;
    footer?: ReactNode;
    label?: string;
    required?: boolean;
    id: string;
    className?: string;
    tooltip?: string | null;
    testId?: string;
};

export const testIds = {
    tooltip: "tooltip",
};
